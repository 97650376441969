var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ClientData" },
    [
      _c(
        "div",
        { staticClass: "ClientData__navigation row row--align-center" },
        _vm._l(_vm.routes, function(route, index) {
          return _c(
            "router-link",
            {
              key: index,
              staticClass:
                "ClientData__navigation-item fc-mid fs-15 capitalize",
              attrs: {
                to: { name: route.path },
                "active-class": "ClientData__navigation-item--active fc-white",
                tag: "a"
              }
            },
            [_vm._v("\n      " + _vm._s(route.text) + "\n    ")]
          )
        }),
        1
      ),
      _c("router-view", { staticClass: "ClientData__router-view" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }