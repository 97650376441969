<template>
  <div class="ClientData">
    <!-- NAVIGATION -->
    <div class="ClientData__navigation row row--align-center">
      <router-link
        v-for="(route, index) in routes"
        :key="index"
        :to="{ name: route.path }"
        active-class="ClientData__navigation-item--active fc-white"
        class="ClientData__navigation-item fc-mid fs-15 capitalize"
        tag="a"
      >
        {{ route.text }}
      </router-link>
    </div>

    <router-view class="ClientData__router-view" />
  </div>
</template>

<script>

export default {
  name: 'ClientData',

  data () {
    return {
      routes: [
        {
          path: 'client-data-general',
          text: 'general',
        },
        {
          path: 'client-data-dilution',
          text: 'dilution',
        },
        {
          path: 'client-data-debtors',
          text: 'debtors',
        },
      ],
    }
  },
}
</script>

<style lang="sass">
@import '../../../../styles/user-profile-navigation.sass'

.ClientData

  &__navigation
    background-color: rgba($background, .3)
    border-top: $border
    padding: rem(10px) rem(29px)

  &__navigation-item
    margin-right: rem(34px)

    &--active
      background-color: #1665D8
      border-radius: $border-radius
      padding: rem(2px) rem(10px)

    &:last-child
      margin-right: 0

  &__router-view
    padding: 0 rem(29px) 2.5rem
</style>